<template>
  <div class="info">
    <div class="top">
      <ul>
        <li>
          <div class="label">病例ID</div>
          <span v-if="info.patience">{{
            info.caseId ? info.caseId : "-"
          }}</span>
        </li>
        <li>
          <div class="label">姓名</div>
          <span v-if="info.patience">{{ info.patience.patienceName }}</span>
        </li>
        <li>
          <div class="label">证件类型</div>
          <span v-if="info.patience && info.patience.paperType == 1"
            >身份证</span
          >
          <span v-if="info.patience && info.patience.paperType == 2"
            >其他国家或地区证件</span
          >
          <span v-if="info.patience && info.patience.paperType == 3"
            >港澳台</span
          >
        </li>
        <li>
          <div class="label">证件号</div>
          <span v-if="info.patience">{{ info.patience.patienceIdCard }}</span>
        </li>
        <li>
          <div class="label">性别</div>
          <span v-if="info.patience">
            <span v-if="info.patience.patienceSex == 1">男</span>
            <span v-if="info.patience.patienceSex == 2">女</span>
          </span>
        </li>
        <li>
          <div class="label">年龄</div>
          <span v-if="info.patience">
            {{ info.patience.patienceAge }}
          </span>
        </li>
        <li>
          <div class="label">出生日期</div>
          <span v-if="info.patience">
            {{ info.patience.patienceBirth }}
          </span>
        </li>
        <li>
          <div class="label">主要诊断</div>
          <span>{{ info.mainDiagnosisName }}</span>
        </li>
        <li>
          <div class="label">其他诊断</div>
          <span>{{ info.otherDiagnosisName }}</span>
        </li>

        <li>
          <div class="label">联系方式</div>
          <span v-if="info.patience">{{ info.patience.patienceLinkTel }}</span>
        </li>
        <li>
          <div class="label">家属联系方式</div>
          <span v-if="info.patience">{{
            info.patience.dependentsContact
          }}</span>
        </li>
        <li>
          <div class="label">责任医生</div>
          <span>{{ info.responsibleDoctorName }}</span>
        </li>
        <li>
          <div class="label">首诊时间</div>
          <span>{{ info.createTime }}</span>
        </li>
        <li>
          <div class="label">居住地区</div>
          <span v-if="info.patience"
            >{{ info.patience.provinceName }}{{ info.patience.cityName
            }}{{ info.patience.countyName }}</span
          >
        </li>
        <li>
          <div class="label">详细地址</div>
          <span v-if="info.patience" style="width: 60%">{{
            info.patience.addressDetail
          }}</span>
        </li>
        <li>
          <div class="label">籍贯</div>
          <span v-if="info.nativePlace">{{ info.nativePlace }}</span>
        </li>
        <li>
          <div class="label">备注</div>
          <div
            style="
              width: 60%;
              word-break: break-all;
              white-space: normal;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
              margin-left: 16px;
            "
          >
            {{ info.mbglContent }}
          </div>
        </li>
      </ul>
    </div>
    <div class="bottom">
      <el-button type="primary" @click="onShow">编辑</el-button>
    </div>
  </div>
</template>
<script>
import qs from "qs";
import moment from "moment";
export default {
  components: {
    InfoEdit: () => import("./InfoEdit.vue"),
  },

  data() {
    return {
      birth: "",
      responsible_doctor_name: this.$route.params.responsible_doctor_name,
      other_diagnosis: this.$route.params.other_diagnosis,
      age: this.$route.params.patienceAge,
      create_time: this.$route.params.create_time,
      patience_name: this.$route.params.patience_name,
      patience_id_card: this.$route.params.patience_id_card,
      update_time: this.$route.params.update_time,
      main_diagnosis: this.$route.params.main_diagnosis,
      patience_sex: this.$route.params.patience_sex,
      type: this.$route.params.type,
      patience_link_tel: this.$route.params.patience_link_tel,
      info: {},
    };
  },
  watch: {
    "info.patience.patienceIdCard": function (v) {
      console.log("watchwatchwatchwatchwatchdataForm.patienceIdCard");
      if (this.info.patience.paperType == 1) {
        console.log("info.patience.paperType == 1)");
        var len = (v + "").length;
        // console.log(len);
        var strBirthday = "";
        let genderCode;
        if (len == 18) {
          //处理18位的身份证号码从号码中得到生日和性别代码
          strBirthday =
            v.substr(6, 4) + "/" + v.substr(10, 2) + "/" + v.substr(12, 2);
          genderCode = v.charAt(16);
        }
        if (len == 15) {
          strBirthday =
            "19" +
            v.substr(6, 2) +
            "/" +
            v.substr(8, 2) +
            "/" +
            v.substr(10, 2);
          genderCode = v.charAt(14);
        }
        if (
          (this.info.patience.patienceSex == null ||
            this.info.patience.patienceSex == "" ||
            this.info.patience.patienceSex == "null") &&
          genderCode &&
          !isNaN(genderCode)
        ) {
          console.log(
            "(this.info.patience.patienceSex==null||this.info.patience.patienceSex=='')"
          );
          // 两代身份证号码的性别代码都为男奇女偶
          if (parseInt(genderCode) % 2 == 0) {
            this.info.patience.patienceSex = "2";
          } else {
            this.info.patience.patienceSex = "1";
          }
        } else {
          console.log(
            "this.info.patience.patienceSex:",
            this.info.patience.patienceSex == "null"
          );
        }

        //时间字符串里，必须是“/”
        var birthDate = new Date(strBirthday);
        let birth = moment(birthDate).format("YYYY-MM-DD");
        // console.log(birthDate,'birthDate',birth);
        var nowDateTime = new Date();
        var age = nowDateTime.getFullYear() - birthDate.getFullYear();
        //再考虑月、天的因素;.getMonth()获取的是从0开始的，这里进行比较，不需要加1
        if (
          nowDateTime.getMonth() < birthDate.getMonth() ||
          (nowDateTime.getMonth() == birthDate.getMonth() &&
            nowDateTime.getDate() < birthDate.getDate())
        ) {
          age--;
        }
        // console.log(age,'age');
        if (
          (this.info.patience.patienceBirth == null ||
            this.info.patience.patienceBirth == "") &&
          age
        ) {
          // console.log(this.info.patience,'info.patience');
          this.info.patience.patienceAge = age;
          this.info.patience.patienceBirth = birth;
          this.ageFlag = true;
        }
      }
    },
    "info.patience.patienceBirth": function (v) {
      //时间字符串里，必须是“/”
      var birthDate = new Date(v);
      let birth = moment(birthDate).format("YYYY-MM-DD");
      // console.log(birthDate,'birthDate',birth);
      var nowDateTime = new Date();
      var age = nowDateTime.getFullYear() - birthDate.getFullYear();
      //再考虑月、天的因素;.getMonth()获取的是从0开始的，这里进行比较，不需要加1
      if (
        nowDateTime.getMonth() < birthDate.getMonth() ||
        (nowDateTime.getMonth() == birthDate.getMonth() &&
          nowDateTime.getDate() < birthDate.getDate())
      ) {
        age--;
      }
      console.log(age);
      // console.log(age,'age');
      if (age) {
        // console.log(this.info.patience,'info.patience');
        this.info.patience.patienceAge = age;

        this.ageFlag = true;
      }
    },
  },
  mounted() {
    this.getInfo();
    let v = this.$route.params.patience_id_card;
    var len = (v + "").length;
    // console.log(len);
    var strBirthday = "";
    let genderCode;
    if (len == 18) {
      //处理18位的身份证号码从号码中得到生日和性别代码
      strBirthday =
        v.substr(6, 4) + "/" + v.substr(10, 2) + "/" + v.substr(12, 2);
      genderCode = v.charAt(16);
    }
    if (len == 15) {
      strBirthday =
        "19" + v.substr(6, 2) + "/" + v.substr(8, 2) + "/" + v.substr(10, 2);
      genderCode = v.charAt(14);
    }
    if (genderCode && !isNaN(genderCode)) {
      // 两代身份证号码的性别代码都为男奇女偶
      //           if (parseInt(genderCode) % 2 == 0) {
      //              val.patience_sex = '2'
      //           }else{
      // 		val.patience_sex = '1'
      // 	}
    }

    //时间字符串里，必须是“/”
    var birthDate = new Date(strBirthday);
    let birth = moment(birthDate).format("YYYY-MM-DD");
    this.birth = birth;
  },

  methods: {
    getInfo() {
      this.$httpAes({
        url: this.$http.adornUrl(
          "/teamsPatience/info?id=" + JSON.parse(localStorage.patientInfo).id
        ),
        method: "post",
		 data: {
        //   id:this.$route.params.id
        stringParam1: JSON.parse(localStorage.patientInfo).id,
      },
       
      }).then(({ data }) => {
        this.info = data.data;
        this.info.patience.patienceSex = this.info.patience.patienceSex + "";
        if (this.info.nativePlace) {
          let nativePlace = JSON.parse(this.info.nativePlace);
          this.info.nativePlace =
            nativePlace.provinceName +
            nativePlace.cityName +
            nativePlace.blockName;
        }
      });
    },
    onShow() {
      this.$emit("handleShow", "InfoEdit");
    },
  },
};
</script>

<style lang="scss" scoped>
.info {
  // height: 300px;
  .top {
    overflow: hidden;
    li {
      width: 50%;
      float: left;
    }
    // height: 200px;

    //   background-color: aqua;
    ul {
      li {
        display: flex;
        padding: 12px 0px;
        .label {
          width: 140px;
          height: 22px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          line-height: 22px;
          text-align: right;
        }
        span {
          margin-left: 16px;
          height: 22px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 22px;
        }
      }
    }
  }
  .bottom {
    width: 100%;
    height: 100px;
    text-align: center;
    padding-top: 40px;
    // background-color: beige;
    .el-button {
      width: 74px;
      height: 36px;
      background: #0099ff;
      border-radius: 2px;
    }
  }
}
</style>
